import LinearScaleIcon from '@mui/icons-material/LinearScale';
import LocalOfferIcon from '@mui/icons-material/LocalOfferOutlined';
import ClientOrdersIcon from '../components/common/bbfc-components/icons/ClientOrders';
import SubmissionsIcon from '../components/common/bbfc-components/icons/Submissions';
import MyTasksIcon from '../components/common/bbfc-components/icons/MyTasks';
import TasksIcon from '../components/common/bbfc-components/icons/Tasks';
import FacilitiesIcon from '../components/common/bbfc-components/icons/Facilities';
import SearchIcon from '../components/common/bbfc-components/icons/Search';
import UsersIcon from '../components/common/bbfc-components/icons/Users';
import RolesIcon from '../components/common/bbfc-components/icons/Roles';
import CompaniesIcon from '../components/common/bbfc-components/icons/Companies';
import PriceCalculatorIcon from '../components/common/bbfc-components/icons/PriceCalculator';
import ComplianceUiIcon from '../components/common/bbfc-components/icons/ComplianceUi';
import SelfRatingImportsIcon from '../components/common/bbfc-components/icons/SelfRatingImports';

import { USER_PERMISSION } from './constants';

export const MENU_ITEMS = [
  {
    label: 'Client Orders',
    IconComponent: ClientOrdersIcon,
    href: '/clientorders',
    paths: [
      '/clientorders',
      '/clientorders/v1/add',
      '/clientorders/v2/add',
      '/clientorders/v3/add',
      '/clientorders/[id]',
    ],
    permissions: [USER_PERMISSION.CAN_VIEW_CLIENTORDER_PAGE],
  },
  {
    label: 'Submissions',
    IconComponent: SubmissionsIcon,
    href: '/submissions',
    paths: ['/submissions', '/submissions/[id]'],
    permissions: [USER_PERMISSION.CAN_VIEW_WORKORDER_PAGE],
  },
  {
    label: 'Self Ratings',
    IconComponent: SelfRatingImportsIcon,
    href: '/self-ratings',
    paths: ['/self-ratings', '/self-ratings/import', '/self-ratings/[id]'],
    permissions: [USER_PERMISSION.CAN_VIEW_SELF_RATING_IMPORT],
  },
  {
    label: 'My Tasks',
    IconComponent: MyTasksIcon,
    href: '/mytasks?filters=status%3Dopen',
    paths: ['/mytasks'],
    permissions: [USER_PERMISSION.EXTERNAL_UPLOADER],
  },
  {
    label: 'Tasks',
    IconComponent: TasksIcon,
    href: '/tasks?filters=status%3Dopen',
    paths: ['/tasks'],
    permissions: [USER_PERMISSION.CAN_VIEW_TASK_PAGE],
  },
  {
    label: 'Facilities',
    IconComponent: FacilitiesIcon,
    href: '/facilities',
    paths: ['/facilities'],
    permissions: [USER_PERMISSION.CAN_VIEW_FACILITIES_PAGE],
  },
  {
    label: 'Archive',
    IconComponent: LinearScaleIcon,
    href: '/archive',
    paths: ['/archive', '/archive/[id]'],
    permissions: [USER_PERMISSION.CAN_VIEW_ARCHIVE_PAGE],
  },
  {
    label: 'Search',
    IconComponent: SearchIcon,
    href: '/search?filters=collection_type%3Dfilm%26collection_type%3Dseries&page=0',
    paths: ['/search', '/search/[id]', '/search/play/[id]'],
    permissions: [USER_PERMISSION.CAN_VIEW_SEARCH_PAGE],
  },
  {
    label: 'Users',
    IconComponent: UsersIcon,
    href: '/users',
    paths: [
      '/users',
      '/users/[id]',
      '/users/[id]/edit',
      '/users/add',
      '/users/add-google',
    ],
    permissions: [USER_PERMISSION.CAN_VIEW_USER_MANAGEMENT_PAGE],
  },
  {
    label: 'Roles',
    IconComponent: RolesIcon,
    href: '/roles',
    paths: ['/roles', '/roles/add', '/roles/[id]', '/roles/[id]/edit'],
    permissions: [USER_PERMISSION.CAN_VIEW_ROLE_PAGE],
  },
  {
    label: 'Companies',
    IconComponent: CompaniesIcon,
    href: '/companies',
    paths: [
      '/companies',
      '/companies/[id]',
      '/companies/[id]/edit',
      '/companies/add',
    ],
    permissions: [USER_PERMISSION.CAN_VIEW_COMPANY_PAGE],
  },
  {
    label: 'Price Calculator',
    IconComponent: PriceCalculatorIcon,
    href: '/pricecalculator',
    paths: ['/pricecalculator'],
    permissions: [USER_PERMISSION.CAN_VIEW_PRICE_CALCULATOR_PAGE],
  },
  {
    label: 'Compliance UI',
    IconComponent: ComplianceUiIcon,
    href: '/compliance-ui',
    paths: ['/compliance-ui'],
    external: true,
    permissions: [USER_PERMISSION.CAN_VIEW_COMPLIANCE_PLATFORM],
  },
  {
    label: 'Tags',
    IconComponent: LocalOfferIcon,
    href: '/tags',
    paths: ['/tags'],
    permissions: [USER_PERMISSION.CAN_VIEW_TAG_PAGE],
  },
  {
    label: 'Tag Search',
    IconComponent: LocalOfferIcon,
    href: '/tag-search?filters=has_video%3DYes',
    paths: ['/tag-search', '/tag-search/exports', '/tag-search/exports/[id]'],
    external: true,
    permissions: [USER_PERMISSION.CAN_VIEW_TAG_SEARCH_PAGE],
  },
];
